import React, { useState, useEffect } from "react";
import PageBanner from "../Components/PageBanner";
import FileUpload from "../Components/FileUpload";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";

function Googleform() {
  const [language, setLanguage] = useState("English");
  const [loading, setLoading] = useState(false); // State for loader
  const navigate = useNavigate(); // Hook for navigation

  // Function to handle photo upload
  const [states, setStates] = useState([]);
  const [permanentCities, setPermanentCities] = useState([]);
  const [currentCities, setCurrentCities] = useState([]);

  const [permanentState, setPermanentState] = useState("");
  const [permanentCity, setPermanentCity] = useState("");
  const [currentState, setCurrentState] = useState("");
  const [currentCity, setCurrentCity] = useState("");

  const [adharFrontPath, setAdharFrontPath] = useState(null);
  const [adharBackPath, setAdharBackPath] = useState(null);
  const [panCardPath, setPanCardPath] = useState(null);
  const [certificatePath, setCertificatePath] = useState(null);
  //
  // Form State
  const [formData, setFormData] = useState({
    name: "",
    mobileNo: "",
    gender: "",
    dob: "1900-01-01",
    bloodGroup: "",
    currentAddress: "",
    currentCountry: "India",
    currentStateName: "",
    currentCity: "",
    currentPincode: "",
    permanentAddress: "",
    permanentCountry: "India",
    permanentStateName: "",
    permanentCity: "",
    permanentPincode: "",
    bankName: "",
    accountNo: "",
    ifscCode: "",
    schoolName: "",
    class: "",
    college: "",
    degree: "",
    companyName1: "",
    jobStartDate1: "1900-01-01",
    jobEndDate1: "1900-01-01",
    workResponsibilty1: "",
    companyName2: "",
    jobStartDate2: "1900-01-01",
    jobEndDate2: "1900-01-01",
    workResponsibilty2: "",
    refrenceName: "",
    refrenceMobile: "",
    refrenceAddress: "",
    refrenceName1: "",
    refrenceMobile1: "",
    refrenceAddress1: "",
    emContactName: "",
    emContactNo: "",
    profilePic: "Documents/defualtimage.jpg",
    adharFront: "Documents/defualtimage.jpg",
    adharBack: "Documents/defualtimage.jpg",
    panCard: "Documents/defualtimage.jpg",
    degreePic: "Documents/defualtimage.jpg",
  });

  // Function to toggle language
  const toggleLanguage = () => {
    setLanguage(language === "English" ? "Hindi" : "English");
  };

  //
  // Fetch states on component mount
  // Fetch states on component mount
  useEffect(() => {
    axios
      .get("https://world.datamanindia.com/api/AllCountry/State/India")
      .then((response) => {
        if (response.data.status) {
          setStates(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching states:", error));
  }, []);

  // Fetch cities for Permanent Address
  const handlePermanentStateChange = (stateName) => {
    setPermanentState(stateName);
    axios
      .get(`https://world.datamanindia.com/api/AllCountry/City/${stateName}`)
      .then((response) => {
        if (response.data.status) {
          setPermanentCities(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching cities:", error));
  };

  // Fetch cities for Current Address
  const handleCurrentStateChange = (stateName) => {
    setCurrentState(stateName);
    axios
      .get(`https://world.datamanindia.com/api/AllCountry/City/${stateName}`)
      .then((response) => {
        if (response.data.status) {
          setCurrentCities(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching cities:", error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    console.log(formData);
    axios
      .post(
        "https://api.swarnimayu.com/api/RegisterApplication/AddRegisterApplication",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false); // Stop loading
        alert("Form submitted successfully!");
        navigate("/home"); // Adjust the route to match your home page
      })
      .catch((error) => {
        setLoading(false); // Stop loading
        alert("Form Not submitted..!");
        console.error("Error submitting form:", error);
      });
  };

  return (
    <>
      <PageBanner
        name=" Application Form"
        image="assets/images/services/Eqvepment/Banner.jpg"
      />
      <div className="registration-form container mb-5 pb-5">
        {loading && <Loader />} {/* Show loader when loading is true */}
        {/* Toggle Button */}
        <form onSubmit={handleSubmit}>
          <div className="row  mt-5">
            <div className="col-md-8">
              <h2>
                {language === "English"
                  ? "Employee Application Form"
                  : "नौकरी आवेदन फॉर्म"}
              </h2>
              <br />
              <button onClick={toggleLanguage} className="btn btn-primary">
                {language === "English"
                  ? "Switch to Hindi"
                  : "Switch to English"}
              </button>
            </div>

            <div className="form-group col-md-4 text-right pr-4">
              <FileUpload
                label={
                  language === "English" ? "Applicant Photo" : "आवेदक फोटो"
                }
                onFileUpload={(path) => {
                  // console.log("File path received in parent:", path); // Log received path
                  setFormData((prev) => ({ ...prev, profilePic: path }));
                }}
                placeholderImage="assets/images/formlogo/user.jpg"
              />
            </div>
          </div>
          <div className="container-fluid mt-5">
            {/* personal Details Fieldset */}
            <fieldset className="border p-4 mb-4">
              <legend className="w-auto px-2">
                {language === "English"
                  ? "Personal Information"
                  : "व्यक्तिगत जानकारी"}
              </legend>

              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="name">
                    <span style={{ color: "red" }}>*</span>{" "}
                    {language === "English" ? "Name:" : "नाम:"}
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    pattern="[A-Za-z\s]{3,50}"
                    required
                    onChange={handleInputChange}
                    title={
                      language === "English"
                        ? "Only letters and spaces allowed."
                        : "केवल अक्षर और स्थान मान्य हैं।"
                    }
                  />
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="mobileNo">
                    <span style={{ color: "red" }}>*</span>
                    {language === "English" ? "Mobile No:" : "मोबाइल नंबर:"}
                  </label>
                  <input
                    type="tel"
                    id="mobileNo"
                    name="mobileNo"
                    className="form-control"
                    maxLength="10"
                    pattern="\d{10}"
                    required
                    title={
                      language === "English"
                        ? "Enter a 10-digit mobile number."
                        : "10 अंकों का मोबाइल नंबर दर्ज करें।"
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10);
                    }}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="gender">
                    {language === "English" ? "Select Gender:" : "लिंग चुनें:"}
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    className="form-control"
                    onChange={handleInputChange}
                  >
                    <option value="">
                      {language === "English" ? "Gender" : "लिंग"}
                    </option>
                    <option value="Male">
                      {language === "English" ? "Male" : "पुरुष"}
                    </option>
                    <option value="Female">
                      {language === "English" ? "Female" : "महिला"}
                    </option>
                    <option value="Other">
                      {language === "English" ? "Other" : "अन्य"}
                    </option>
                  </select>
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="dob">
                    {language === "English" ? "Date of Birth:" : "जन्म तिथि:"}
                  </label>
                  <input
                    type="date"
                    id="dob"
                    name="dob"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="bloodGroup">
                    {language === "English" ? "Blood Group:" : "रक्त समूह:"}
                  </label>
                  <select
                    id="bloodGroup"
                    name="bloodGroup"
                    className="form-control"
                    onChange={handleInputChange}
                  >
                    <option value="">
                      {language === "English" ? "Blood Group" : "रक्त समूह"}
                    </option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                  </select>
                </div>
              </div>
            </fieldset>

            {/* Addess Details Fieldset */}
            <fieldset className="border p-4 mb-4">
              <legend className="w-auto px-2">
                {language === "English" ? "Address Information" : "पता जानकारी"}
              </legend>

              {/* Current Address */}
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="currentAddress">
                    {language === "English"
                      ? "Current Address:"
                      : "वर्तमान पता:"}
                  </label>
                  <textarea
                    id="currentAddress"
                    name="currentAddress"
                    className="form-control"
                    onChange={handleInputChange}
                  ></textarea>
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="currentStateName">
                    {language === "English" ? "State:" : "राज्य:"}
                  </label>
                  <select
                    id="currentStateName"
                    name="currentStateName"
                    className="form-control"
                    value={currentState}
                    onChange={(e) => {
                      handleCurrentStateChange(e.target.value);
                      handleInputChange(e);
                    }}
                  >
                    <option value="">
                      {language === "English" ? "Select State" : "राज्य चुनें"}
                    </option>
                    {states.map((state, index) => (
                      <option key={index} value={state.state_name}>
                        {state.state_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="currentCity">
                    {language === "English" ? " City:" : "शहर:"}
                  </label>
                  <select
                    id="currentCity"
                    name="currentCity"
                    className="form-control"
                    value={currentCity}
                    onChange={(e) => {
                      setCurrentCity(e.target.value);
                      handleInputChange(e);
                    }}
                  >
                    <option value="">
                      {language === "English" ? "City" : "शहर चुनें"}
                    </option>
                    {currentCities.map((city, index) => (
                      <option key={index} value={city.city_name}>
                        {city.city_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="currentPincode">
                    {language === "English" ? "PinCode: " : "बैंक का नाम:"}
                  </label>
                  <input
                    type="text"
                    id="currentPincode"
                    name="currentPincode"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* Permanent Address */}
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="permanentAddress">
                    {language === "English"
                      ? "Permanent Address:"
                      : "स्थायी पता:"}
                  </label>
                  <textarea
                    id="permanentAddress"
                    name="permanentAddress"
                    className="form-control"
                    onChange={handleInputChange}
                  ></textarea>
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="permanentStateName">
                    {language === "English" ? "State:" : "राज्य:"}
                  </label>
                  <select
                    id="permanentStateName"
                    name="permanentStateName"
                    className="form-control"
                    value={permanentState}
                    onChange={(e) => {
                      handlePermanentStateChange(e.target.value);
                      handleInputChange(e);
                    }}
                  >
                    <option value="">
                      {language === "English" ? "Select State" : "राज्य चुनें"}
                    </option>
                    {states.map((state, index) => (
                      <option key={index} value={state.state_name}>
                        {state.state_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="permanentCity">
                    {language === "English" ? "City:" : "वर्तमान शहर:"}
                  </label>
                  <select
                    id="permanentCity"
                    name="permanentCity"
                    className="form-control"
                    value={permanentCity}
                    onChange={(e) => {
                      setPermanentCity(e.target.value);
                      handleInputChange(e);
                    }}
                  >
                    <option value="">
                      {language === "English" ? "Select City" : "शहर चुनें"}
                    </option>
                    {permanentCities.map((city, index) => (
                      <option key={index} value={city.city_name}>
                        {city.city_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="permanentPincode">
                    {language === "English" ? "Pincode:" : "बैंक का नाम:"}
                  </label>
                  <input
                    type="text"
                    id="permanentPincode"
                    name="permanentPincode"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </fieldset>

            {/* Bank Details Fieldset */}
            <fieldset className="border p-4 mb-4">
              <legend className="w-auto px-2">
                {language === "English" ? "Bank Details" : "बैंक विवरण"}
              </legend>
              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="bankName">
                    {language === "English" ? "Bank Name:" : "बैंक का नाम:"}
                  </label>
                  <input
                    type="text"
                    id="bankName"
                    name="bankName"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="accountNo">
                    {language === "English"
                      ? "Account Number:"
                      : "खाता संख्या:"}
                  </label>
                  <input
                    type="text"
                    id="accountNo"
                    name="accountNo"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor=" ifscCode">
                    {language === "English" ? "IFSC Code:" : "आईएफएससी कोड:"}
                  </label>
                  <input
                    type="text"
                    id="ifscCode"
                    name="ifscCode"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </fieldset>

            {/* education Section */}
            <fieldset className="border p-4 mb-4">
              <legend className="w-auto px-2">
                {language === "English"
                  ? "Education Details"
                  : "शैक्षिक जानकारी"}
              </legend>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="schoolName">
                    {language === "English"
                      ? "School Name:"
                      : "विद्यालय का नाम:"}
                  </label>
                  <input
                    type="text"
                    id="schoolName"
                    name="schoolName"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="class">
                    {language === "English" ? "School class:" : "कक्षा:"}
                  </label>
                  <select
                    id=" class"
                    name="class"
                    className="form-control"
                    onChange={handleInputChange}
                  >
                    <option value="">
                      {language === "English" ? "Select class" : "कक्षा चुनें"}
                    </option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="college">
                    {language === "English" ? "College Name:" : "कॉलेज का नाम:"}
                  </label>
                  <input
                    type="text"
                    id="college"
                    name="college"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="degree">
                    {language === "English" ? "Degree :" : "डिग्री:"}
                  </label>
                  <input
                    type="text"
                    id="degree"
                    name="degree"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </fieldset>

            {/* Work Experience Section */}
            <fieldset className="border p-4 mb-4">
              <legend className="w-auto px-2">
                {language === "English" ? "Work Experience" : "कार्य अनुभव"}
              </legend>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="companyName1">
                    {language === "English"
                      ? "1. Company/Organization Name:"
                      : "1. कंपनी/संगठन का नाम:"}
                  </label>
                  <input
                    type="text"
                    id="companyName1"
                    name="companyName1"
                    className="form-control"
                    pattern="[A-Za-z\s]{2,50}"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="jobStartDate1">
                    {language === "English"
                      ? "Job Start Date:"
                      : "नौकरी आरंभ तिथि:"}
                  </label>
                  <input
                    type="date"
                    id="jobStartDate1"
                    name="jobStartDate1"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="jobEndDate1">
                    {language === "English"
                      ? "Job End Date:"
                      : "नौकरी समाप्ति तिथि:"}
                  </label>
                  <input
                    type="date"
                    id="jobEndDate1"
                    name="jobEndDate1"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="workResponsibilty1">
                    {language === "English"
                      ? "Work/Responsibilities:"
                      : "कार्य/जिम्मेदारियां:"}
                  </label>
                  <textarea
                    id="workResponsibilty1"
                    name="workResponsibilty1"
                    className="form-control"
                    pattern="[A-Za-z\s,;]{2,100}"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="companyName2">
                    {language === "English"
                      ? "2. Company/Organization Name:"
                      : "2. कंपनी/संगठन का नाम:"}
                  </label>
                  <input
                    type="text"
                    id="companyName2"
                    name="companyName2"
                    className="form-control"
                    pattern="[A-Za-z\s]{2,50}"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor=" jobStartDate2">
                    {language === "English"
                      ? "Job Start Date:"
                      : "नौकरी आरंभ तिथि:"}
                  </label>
                  <input
                    type="date"
                    id=" jobStartDate2"
                    name="jobStartDate2"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="jobEndDate2">
                    {language === "English"
                      ? "Job End Date:"
                      : "नौकरी समाप्ति तिथि:"}
                  </label>

                  <input
                    type="date"
                    id="jobEndDate2"
                    name="jobEndDate2"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="workResponsibilty2">
                    {language === "English"
                      ? "Work/Responsibilities:"
                      : "कार्य/जिम्मेदारियां:"}
                  </label>
                  <textarea
                    id="workResponsibilty2"
                    name="workResponsibilty2"
                    className="form-control"
                    pattern="[A-Za-z\s,;]{2,100}"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
            </fieldset>

            {/* Personal Reference Section */}
            <fieldset className="border p-4 mb-4">
              <legend className="w-auto px-2">
                {language === "English"
                  ? "Personal Reference"
                  : "व्यक्तिगत संदर्भ"}
              </legend>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor=" refrenceName">
                    {language === "English" ? "Reference Name:" : "संदर्भ नाम:"}
                  </label>
                  <input
                    type="text"
                    id=" refrenceName"
                    name="refrenceName"
                    className="form-control"
                    pattern="[A-Za-z\s]{2,50}"
                    title={
                      language === "English"
                        ? "Only letters and spaces allowed."
                        : "केवल अक्षर और स्थान की अनुमति है।"
                    }
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="refrenceMobile">
                    {language === "English"
                      ? "Reference Mobile No:"
                      : "संदर्भ मोबाइल नंबर:"}
                  </label>
                  <input
                    type="tel"
                    id="refrenceMobile"
                    name="refrenceMobile"
                    className="form-control"
                    maxLength="10"
                    pattern="\d{10}"
                    title={
                      language === "English"
                        ? "Enter a 10-digit mobile number."
                        : "10-अंकों का मोबाइल नंबर दर्ज करें।"
                    }
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10);
                    }}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="refrenceAddress">
                    {language === "English"
                      ? "Reference Address:"
                      : "संदर्भ पता:"}
                  </label>
                  <textarea
                    id="refrenceAddress"
                    name="refrenceAddress"
                    className="form-control"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <hr />
                <div className="form-group col-md-6">
                  <label htmlFor="refrenceName1">
                    {language === "English" ? "Reference Name:" : "संदर्भ नाम:"}
                  </label>
                  <input
                    type="text"
                    id="refrenceName1"
                    name="refrenceName1"
                    className="form-control"
                    pattern="[A-Za-z\s]{2,50}"
                    title={
                      language === "English"
                        ? "Only letters and spaces allowed."
                        : "केवल अक्षर और स्थान की अनुमति है।"
                    }
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="refrenceMobile1">
                    {language === "English"
                      ? "Reference Mobile No:"
                      : "संदर्भ मोबाइल नंबर:"}
                  </label>
                  <input
                    type="tel"
                    id="refrenceMobile1"
                    name="refrenceMobile1"
                    className="form-control"
                    maxLength="10"
                    pattern="\d{10}"
                    title={
                      language === "English"
                        ? "Enter a 10-digit mobile number."
                        : "10-अंकों का मोबाइल नंबर दर्ज करें।"
                    }
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10);
                    }}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="refrenceAddress1">
                    {language === "English"
                      ? "Reference Address:"
                      : "संदर्भ पता:"}
                  </label>
                  <textarea
                    id="refrenceAddress1"
                    name="refrenceAddress1"
                    className="form-control"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
            </fieldset>

            {/* Emergency Contact Section */}
            <fieldset className="border p-4 mb-4">
              <legend className="w-auto px-2">
                {language === "English"
                  ? "Emergency Contact"
                  : "आपातकालीन संपर्क"}
              </legend>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="emContactName">
                    {language === "English"
                      ? "Emergency Contact Name:"
                      : "आपातकालीन संपर्क नाम:"}
                  </label>
                  <input
                    type="text"
                    id="emContactName"
                    name="emContactName"
                    className="form-control"
                    pattern="[A-Za-z\s]{2,50}"
                    title={
                      language === "English"
                        ? "Only letters and spaces allowed."
                        : "केवल अक्षर और स्थान की अनुमति है।"
                    }
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="emContactNo">
                    {language === "English"
                      ? "Emergency Contact Number:"
                      : "आपातकालीन संपर्क नंबर:"}
                  </label>
                  <input
                    type="tel"
                    id=" emContactNo"
                    name="emContactNo"
                    className="form-control"
                    maxLength="10"
                    pattern="\d{10}"
                    title={
                      language === "English"
                        ? "Enter a 10-digit emergency contact number."
                        : "10-अंकों का आपातकालीन संपर्क नंबर दर्ज करें।"
                    }
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10);
                    }}
                  />
                </div>
              </div>
            </fieldset>

            {/* Applicant documents Section */}
            <fieldset className="border p-4 mb-4">
              <legend className="w-auto px-2">
                {language === "English"
                  ? "Upload Documents"
                  : "दस्तावेज़ अपलोड करें"}
              </legend>
              <div className="row">
                <div className="form-group col-md-3">
                  <FileUpload
                    label={
                      language === "English" ? "Aadhar Front" : "आधार फ्रंट"
                    }
                    onFileUpload={(path) => {
                      // console.log("File path received in parent:", path); // Log received path
                      setFormData((prev) => ({ ...prev, adharFront: path }));
                    }}
                    placeholderImage="assets/images/formlogo/aadhaarcard.jpg"
                  />
                </div>
                <div className="form-group col-md-3">
                  <FileUpload
                    label={language === "English" ? "Aadhar Back" : "आधार बैक"}
                    onFileUpload={(path) => {
                      // console.log("File path received in parent:", path); // Log received path
                      setFormData((prev) => ({ ...prev, adharBack: path }));
                    }}
                    placeholderImage="assets/images/formlogo/aadhaarcard.jpg"
                  />
                </div>
                <div className="form-group col-md-3">
                  <FileUpload
                    label={language === "English" ? "Pan Card" : "पैन कार्ड"}
                    onFileUpload={(path) => {
                      // console.log("File path received in parent:", path); // Log received path
                      setFormData((prev) => ({ ...prev, panCard: path }));
                    }}
                    placeholderImage="assets/images/formlogo/pancardr.jpg"
                  />
                </div>
                <div className="form-group col-md-3">
                  <FileUpload
                    label={
                      language === "English" ? "Certificate" : "प्रमाण पत्र"
                    }
                    onFileUpload={(path) => {
                      // console.log("File path received in parent:", path); // Log received path
                      setFormData((prev) => ({ ...prev, degreePic: path }));
                    }}
                    placeholderImage="assets/images/formlogo/certificatedummy.jpg"
                  />
                </div>
              </div>
            </fieldset>

            <div className="text-right">
              <button
                type="submit"
                className="btn btn-lg px-5 py-2 btn-primary"
              >
                {language === "English" ? "Submit" : "जमा करें"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default Googleform;
