import React, { useState } from "react";
import axios from "axios";

function FileUpload({ label, onFileUpload, placeholderImage }) {
  const [preview, setPreview] = useState(placeholderImage || null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSizeInBytes = 300 * 1024; // 300 KB

      // Check file size
      if (file.size > maxSizeInBytes) {
        alert("File size must be less than 300 KB.");
        return;
      }

      // Check file type
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        alert("Please upload a valid JPG or PNG file.");
        return;
      }

      try {
        setIsUploading(true);

        // Create form data to send the file
        const formData = new FormData();
        formData.append("file", file);

        // Make API request to upload the file
        const response = await axios.post(
          "https://api.swarnimayu.com/api/UploadDocument/ApplicationForm",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("API Response:", response.data); // Debugging log
        const filePath = response.data; // Assuming API returns this
        setPreview(URL.createObjectURL(file)); // Show preview locally
        onFileUpload(filePath); // Pass the file path back to the parent form
        console.log("File path sent to parent:", filePath); // Debugging log
      } catch (error) {
        console.error("File upload error:", error);
        alert("File upload failed. Please try again.");
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div className="form-group text-center">
      <div
        className="profile-photo"
        onClick={() => document.getElementById(label).click()}
      >
        <img
          src={preview || placeholderImage || "assets/images/formlogo/user.jpg"}
          alt={label}
          style={{ width: "150px", height: "150px", cursor: "pointer" }}
        />
      </div>
      <input
        type="file"
        id={label}
        name={label}
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png"
        onChange={handleFileChange}
      />
      <label htmlFor={label}>{label}</label>
      {isUploading && <p>Uploading...</p>}
    </div>
  );
}

export default FileUpload;
