import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
function Candidateprofile() { 
  const organizationName = "SwarnimAyu";
  const candidateName = "Sajid Hussain";
  const contactdetails = "Contact";
  const cardRef = useRef(null); // Reference to the card for download
  const handleDownloadPDF = () => {
    const card = cardRef.current;
    if (!card) return;

    html2canvas(card, { scale: 2 }) // Increases resolution for better quality
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4"); // Portrait, millimeters, A4 size
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("candidate-profile.pdf"); // Download the PDF
      })
      .catch((error) => console.error("Error generating PDF:", error));
  };
  const profilePicture = "assets/images/formlogo/user.jpg"; // Placeholder for profile picture
  const adharcard = "assets/images/formlogo/aadhaarcard.jpg"; // Placeholder for Aadhaar card
  const candidateDetails = {
    education: "M.C.A.",
    languages: "English, Hindi",
    workExperience:
      "5 years in software development with Bootstrap, ASP.NET Core, React JS framework.",
    contact: "Rajeev Chauhan GM, Manpower",
    contactNumber: "9266829800  care@swarnimayu.com",
  };
        
  return (
   <>
  <div
        className="card mx-auto"
        style={{ maxWidth: "800px", marginTop: "10rem" }}
        ref={cardRef} // Attach reference for download
      >
        {/* Organization Name */}
        <div
          className="card-header text-center bg-primary"
          style={{ color: "yellow", fontSize: "24px" }}
        >
          <h3 className="card-title">{organizationName}</h3>
        </div>
        <div className="card-body">
          {/* Download Button */}
          <div className="text-right mb-3">
            <button
              className="btn btn-success btn-sm"
              onClick={handleDownloadPDF}
              style={{ fontWeight: "bold" }}
            >
              Download PDF
            </button>
          </div>

          {/* Candidate Name */}
          <h3
            className="card-title text-center"
            style={{ fontWeight: "500", color: "black" }}
          >
            {candidateName}
          </h3>

          {/* Profile Picture and Details */}
          <div className="row mt-4">
            <div className="col-md-2">
              <img
                src={profilePicture}
                alt="Profile"
                className="border border-primary"
                style={{ width: "100px", height: "100px" }}
              />
            </div>

            <div className="col-md-10">
              <p className="mb-2 border p-3">
                <strong>Education:</strong> {candidateDetails.education}
                <br />
                <strong>Languages:</strong> {candidateDetails.languages}
                <br />
                <strong>Work Experience:</strong>{" "}
                {candidateDetails.workExperience}
              </p>
            </div>
          </div>

          {/* Aadhaar Card and Contact Details */}
          <div className="row mt-4">
            <div className="col-md-4">
              <img
                src={adharcard}
                alt="Aadhaar Card"
                className="border"
                style={{ width: "200px", height: "120px" }}
              />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-6 border">
              <h4 className="card-title">{contactdetails}</h4>
              <p>
                {candidateDetails.contact}
                <br />
                {candidateDetails.contactNumber}
              </p>
            </div>
          </div>
        </div>
      </div>
   </>   
  );
}
export default Candidateprofile;
