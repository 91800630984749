import React from 'react'

function Profile() {
    const ProfileCard = () => {
        const organizationName = "Tech Solutions Inc.";
        const candidateName = "John Doe";
        const profilePicture = "https://via.placeholder.com/100"; // Placeholder for profile picture
        const candidateDetails = {
          education: "B.Sc. in Computer Science",
          languages: "English, Spanish",
          workExperience: "3 years in software development",
        };
  return (
    <div className="card mx-auto my-4" style={{ maxWidth: "400px" }}>
      {/* Organization Name */}
      <div className="card-header text-center bg-primary text-white">
        {organizationName}
      </div>

      <div className="card-body">
        {/* Candidate Name */}
        <h5 className="card-title text-center">{candidateName}</h5>

        {/* Profile Picture and Details */}
        <div className="d-flex align-items-start mt-4">
          <div className="me-3">
            <img
              src={profilePicture}
              alt="Profile"
              className="rounded-circle border border-primary"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div>
            <p className="mb-2">
              <strong>Education:</strong> {candidateDetails.education}
            </p>
            <p className="mb-2">
              <strong>Languages:</strong> {candidateDetails.languages}
            </p>
            <p className="mb-0">
              <strong>Work Experience:</strong> {candidateDetails.workExperience}
            </p>
          </div>
        </div>
      </div>
    </div>

  )
}
}
export default Profile;
