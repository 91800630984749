import React from 'react'
import PageBanner from '../Components/PageBanner'

function RequestUeserData() {
  return (
    <>
        {/* <PageBanner
        name="Request to Delete Acccount"
        image="assets/images/services/legalservice/banner.jpg"
       /> */}

<section class="contact-one">
        <div class="container">
          <div class="block-title text-center">
            <p>Fill form</p>
            <h3>Request to Delete Account</h3>
          </div>
          <form
            action="http://html.tonatheme.com/2021/oberlin/assets/inc/sendemail.php"
            class="contact-one__form contact-form-validated"
          >
            <div class="row">
              <div class="col-md-4">
                <input type="text" placeholder="Full Name" name="name"></input>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  placeholder="Email Address"
                  name="email"
                ></input>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="phone"
                ></input>
              </div>               
              <div class="col-md-12">
                <textarea name="message" placeholder="reason to delte account"></textarea>
              </div>
              <div class="col-md-12 text-center">
                <button type="submit" class="thm-btn contact-one__btn">
                  Submit Now
                </button>
              </div>
            </div>
          </form>
          <div class="result"></div>
        </div>
      </section>
    </>
  )
}

export default RequestUeserData
